import React, { useState } from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import { Tooltip } from '@mui/material';


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0;
  max-width: 900px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.4);
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  backdrop-filter: blur(7px);
  box-shadow: 10px 10px 10px rgba(30, 30, 30, 0.1);
  border-radius: 20px;
  border-left: solid 1px rgba(255, 255, 255, 0.3);
  border-top: solid 1px rgba(255, 255, 255, 0.8);

  h1 {
    font-size: 35px;
    font-weight: 800;
    color: white;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-top: 3rem;
    background: #fff;
    padding: 3rem;
    color: #222;
    box-shadow: 0 0 1px 0 rgba(8, 11, 14, 0.06),
    0 6px 6px -1px rgba(8, 11, 14, 0.1);
    border-radius: 1rem;
  }

  form {
    display: box;
    text-align: center;
    margin-top: 1rem;
  }
`;

const InputSection = styled.div`
  width: 45%;
  min-width: 350px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  label {
    tex-transform: uppercase;
    font-weight: 400;
    color: white;
    margin-bottom: 0.5rem;
  }

  input {
    background: rgba(255, 255, 255, 0.8);
    height: 35px;
    border: none;
    border-radius: 10px;
    padding: 0 1rem;
    color: #222;
    font-weight: 500;
    box-shadow: 0 0 1px 0 rgba(8, 11, 14, 0.06),
      0 6px 6px -1px rgba(8, 11, 14, 0.1);
    transition: all 0.3s ease-in-out;
    &:hover,
    &:focus {
      box-shadow: 0 0 1px 0 rgba(8, 11, 14, 0.06),
        0 16px 16px -1px rgba(8, 11, 14, 0.1);
    }
  }
`;

const SubmitButton = styled.button`
  background-color: azure;
  color: #222;
  border: none;
  width: 150px;
  height: 36px;
  font-size: 14px;
  letter-spacing: 0.03em;
  line-height: 36px;
  border-radius: 5px;
  box-shadow: 0 0 1px rgba(8, 11, 14, 0.06),
    0 6px 6px -1px rgba(8, 11, 14, 0.1);
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 1px 0 rgba(8, 11, 14, 0.06),
    0 16px 16px -1px rgba(8, 11, 14, 0.1);
  }
`;

const Error = styled.h4`
  color: firebrick;
  font-size: 12px;
  margin-bottom: 1rem;
`;

  const FormContainer = () => {
  const [monthlyExp, setMonthlyExp] = useState("");
  const [passiveInc, setPassiveInc] = useState("");
  const [liquidAssets, setLiquidAssets] = useState("");
  const [fScoreY, setFScoreY] = useState();
  const [fScoreM, setFScoreM] = useState();
  
  const submitCalculation = async (e) => {
    e.preventDefault();

    const validatedExpenses = await validateField(monthlyExp, setMonthlyExp);
    const validatedIncome = await validateField(passiveInc, setPassiveInc);
    const validatedAssets = await validateField(liquidAssets, setLiquidAssets);

    if (validatedExpenses && validatedIncome && validatedAssets) {
      console.log("Form is fully validated");
      calculateValues();
    }
  };  
  
  const calculateValues = () => {
    let burnRate = passiveInc - monthlyExp;
    let monthlyFScore = liquidAssets / burnRate;
    let yearlyFScore = monthlyFScore / 12;
    setFScoreY(yearlyFScore);
    setFScoreM(monthlyFScore);
  };
    
  const validateField = (field, setValue) => {
    let int = parseFloat(field);

    if (field === "" || field === 0) {
      setValue({ ...field.values, error: "Please enter a number"});
      return false;
    } else if (isNaN(int)) {
      setValue({ ...field.values, error: "Please enter a number"});
      return false;
    } else {
      setValue(int);
      return true;
    }
  }

  const refreshPage = () => {
    window.location.reload(false);
  }

  return (
    <Container>
      <h1>WealthScore</h1>
      <form>
        <InputSection>
          <Tooltip
            title="Housing, Food, Auto, Entertainment, Travel etc."
          >
            <label>Total Expenses (Monthly)</label>
          </Tooltip>
          <Error>{monthlyExp.error}</Error>
          <input
            name="expenses"
            onChange={(e) => setMonthlyExp(e.target.value)}
            type="text"
            placeholder="1000" />
        </InputSection>
        <InputSection>
          <Tooltip
            title="Business, Real Estate, Dividends, Index Funds, Book, etc. "
          >
            <label>Total Passive Income (Monthly)</label>
          </Tooltip>
          <Error>{passiveInc.error}</Error>
          <input
            name="income"
            onChange={(e) => setPassiveInc(e.target.value)}
            type="text"
            placeholder="4000" />
        </InputSection>
        <InputSection>
          <Tooltip
            title="Cash, Savings, Real Estate, Cryptocurrency, Gold/Silver, Stocks, Bonds, ETFs, Mutual Funds etc."
          >
            <label>Total Liquid Assets (Current)</label>
          </Tooltip>
          <Error>{liquidAssets.error}</Error>
          <input
            name="assets"
            onChange={(e) => setLiquidAssets(e.target.value)}
            type="text"
            placeholder="100000" />
        </InputSection>
        <SubmitButton onClick={(e) => submitCalculation(e)}>Calculate</SubmitButton>
        <SubmitButton onClick={(e) => refreshPage(e)}>Refresh</SubmitButton>
      </form>
      <h3>You have <strong>{numeral(fScoreY).format("0.0")} Years </strong> ({numeral(fScoreM).format("0")} Months) of Financial Freedom</h3>
    </Container>
  );
};

export default FormContainer;

