import React from 'react';
import styled from 'styled-components';
import FormContainer from './components/FormContainer';
import backgroundImg from './assets/mountains.jpeg';

const Container = styled.div`
  background: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  padding: 2rem 1rem;
  position: relative;
`;

const Footer = styled.div`
  background-color: #001529;
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  text-align: center;
  max-height: 30px;
`;

const App = () => {
  return (
    <Container>
      <FormContainer />
      <Footer>
        <h4>©2021 James A. Jerkowski | All Rights Reserved.</h4>
      </Footer>
    </Container>
  )
}

export default App;

